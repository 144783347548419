<template>
  <div class="search-dropdown">
    <div
      class="bg-white"
      :class="[
        showDropdown && 'fixed inset-0 z-50',
      ]"
    >
      <slot name="input" />

      <div
        v-if="showDropdown"
        ref="container"
        class="w-full overflow-y-auto pb-4 lg:max-h-100"
        style="height: calc(100% - 64px)"
      >
        <slot name="results" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showDropdown: Boolean,
  },

  data: () => ({
    iOsFix: false,
  }),

  watch: {
    showDropdown (val) {
      if (! val) {
        document.body.style.removeProperty('position')
      }
      this.$nextTick(() => {
        if (val) {
          document.body.style.position = 'fixed'
        }
      })
    },
  },

  beforeUnmount () {
    document.body.style.removeProperty('position')
  },
}
</script>
