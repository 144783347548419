<template>
  <div>
    <slot name="results" />
    <div class="mx-4 mb-4 lg:border-b-2 lg:border-black" />
    <div v-if="destinations.length">
      <SearchDropdownDestination v-for="dest in destinations.slice(0, 5)" :key="dest.id" :destination="dest " />
    </div>

    <NuxtLink v-if="emojiDestinations.length" :to="`/explore?tt=${selectedEmojis.map(e => e.id).join(',')}`" class="flex px-4 py-2 hover:bg-blue-highlight-2">
      <div class="mr-6 flex size-12 items-center justify-center rounded-lg border border-gray-6">
        <img src="~/assets/images/zoom-icon.svg" alt="Destination">
      </div>
      <div>
        <div class="mt-3 block text-xl leading-tight text-gray-dark">
          Explore All
        </div>
      </div>
    </NuxtLink>

    <div v-if="query && venues.length">
      <SearchDropdownVenue v-for="venue in venues" :key="venue.id" :venue="venue" />
    </div>

    <div v-if="!query || !hasResults" class="mt-6 px-4">
      <div class="letter-sp12 mb-3 text-sm uppercase text-slate">
        <span v-if="!selectedEmojis.length">Search by Emoji</span>
        <span v-else>Add another Emoji</span>
      </div>
      <div class="flex overflow-x-auto py-1 lg:flex-wrap">
        <div v-for="tt in travelTypesToSelect" :key="tt.id" role="button" class="sm-hover:bg-blue-highlight-2 mb-2 mr-2 inline-block whitespace-nowrap rounded border-2 border-gray-200 px-3 py-1" @click="$emit('update:emojisQuery', emojisQuery + tt.emoji)">
          {{ tt.emoji }} {{ tt.name }}
        </div>
        <div v-if="!showAllTravelTypes" role="button" class="sm-hover:bg-blue-highlight-2 mb-2 mr-2 inline-block whitespace-nowrap rounded border-2 border-gray-200 px-3 py-1" @click="showAllTravelTypes = true">
          &#183;&#183;&#183;
        </div>
      </div>
    </div>

    <div v-if="showDefault" class="mt-4 px-4">
      <div class="letter-sp12 mb-3 text-sm uppercase text-slate">
        Explore
      </div>
      <div class="flex overflow-x-auto py-1 lg:flex-wrap">
        <NuxtLink
          v-for="c in continentsToSelect"
          :key="c.id"
          :to="`/explore?c=${c.id}`"
          role="button"
          class="sm-hover:bg-blue-highlight-2 mb-2 mr-2 inline-block whitespace-nowrap rounded border-2 border-gray-200 px-3 py-1"
          @click="$emit('closeDropdown')"
        >
          {{ c.name }}
        </NuxtLink>
        <div v-if="!showAllContinents" role="button" class="sm-hover:bg-blue-highlight-2 mb-2 mr-2 inline-block whitespace-nowrap rounded border-2 border-gray-200 px-3 py-1" @click="showAllContinents = true">
          &#183;&#183;&#183;
        </div>
      </div>
    </div>

    <div v-if="showDefault && nearbyDestinations.length" class="mt-6">
      <div class="letter-sp12 mb-2 px-4 text-sm uppercase text-slate">
        Nearby
      </div>
      <SearchDropdownDestination v-for="dest in nearbyDestinations" :key="dest.id" :destination="dest " />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useSearchStore } from '~/stores/search'
import continents from '~/config/continents.json'
import travelTypes from '~/config/travel-types.json'
import SearchDropdownDestination from '~/components/search-dropdown/SearchDropdownDestination'
import SearchDropdownVenue from '~/components/shared/SearchDropdownVenue'

export default {
  components: { SearchDropdownVenue, SearchDropdownDestination },

  props: {
    query: { type: String, required: true },
    emojisQuery: { type: String, required: true },
    selectedEmojis: { type: Array, required: true },
  },

  emits: ['closeDropdown', 'update:emojisQuery'],

  data: () => ({
    travelTypes,
    continents,
    showAllTravelTypes: false,
    showAllContinents: false,
  }),

  computed: {
    ...mapState(useSearchStore, {
      searchDestinations: s => s.search.data.destinations,
      emojiDestinations: s => s.destinations.data,
      nearbyDestinations: s => s.nearbyDestinations.data,
      venues: s => s.search.data.venues,
    }),

    destinations () {
      // Clear search again when deleting emojis fast
      if (! this.selectedEmojis.length && ! this.query) {
        this.clearSearch()
      }

      return this.emojiDestinations.length ? this.emojiDestinations : this.searchDestinations
    },

    travelTypesToSelect () {
      let tts = this.travelTypes.filter(t => ! this.selectedEmojis.find(e => e.id === t.id))
      if (! this.isMobile && ! this.showAllTravelTypes) { tts = tts.slice(0, 4) }
      return tts
    },

    continentsToSelect () {
      if (! this.isMobile && ! this.showAllContinents) { return this.continents.slice(0, 5) }

      return this.continents
    },

    hasResults () {
      return this.destinations.length || this.venues.length
    },

    showDefault () {
      const hasResults = this.destinations.length || this.venues.length
      const hasQuery = this.query || this.emojisQuery

      return ! hasResults || ! hasQuery
    },

    isMobile () { return ['sm', 'md'].includes(this.$screen.name) },
  },

  methods: {
    ...mapActions(useSearchStore, ['clearSearch']),
  },
}
</script>
