<template>
  <div class="sticky top-0 z-30 flex justify-between bg-white" :class="scroll ? scrollClass : null">
    <div class="flex lg:grow">
      <div class="hidden px-6 py-4 lg:block">
        <slot name="logo">
          <Logo :icon-color="scroll ? scrollLogoIconColor : logoIconColor" :text-color="scroll ? scrollLogoTextColor : logoTextColor" />
        </slot>
      </div>

      <div v-if="showSearch" class="max-w-144 lg:grow lg:pt-3">
        <SearchDropdown topbar placeholder="" />
      </div>
      <div v-else class="px-8 py-4" />
    </div>

    <div class="mr-6 hidden items-center gap-2 lg:flex">
      <NuxtLink class="menu-item" :class="scroll ? scrollMenuItemClass : menuItemClass" to="/explore">
        Explore
      </NuxtLink>
      <NuxtLink class="menu-item" :class="scroll ? scrollMenuItemClass : menuItemClass" to="/about">
        About
      </NuxtLink>
      <NuxtLink class="menu-item" :class="scroll ? scrollMenuItemClass : menuItemClass" to="/experts-choice-awards">
        Experts' Choice
      </NuxtLink>
      <NuxtLink class="menu-item" :class="scroll ? scrollMenuItemClass : menuItemClass" to="/api">
        API
      </NuxtLink>
      <ClientOnly>
        <DropdownSelect :model-value="currency.code" :options="selectCurrencies" mobile-title="Currencies" align="right" @update:model-value="code => updateCurrency(code)">
          <template #button="{ click }">
            <button class="menu-item flex items-center gap-1 focus:outline-none" :class="scroll ? scrollMenuItemClass : menuItemClass" @click="click">
              <span>{{ symbol }}</span>
              <svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.649682L0.649682 0L4 3.35032L7.35032 0L8 0.649682L4 4.64968L0 0.649682Z" :fill="scroll ? scrollLogoTextColor : logoTextColor" />
              </svg>
            </button>
          </template>
        </DropdownSelect>
        <DropdownMenu v-model="showUserMenu" right>
          <template #button>
            <SpinnerSm v-if="loading" class="menu-item menu-item-user" size="14px" />
            <a v-else href="#" class="menu-item menu-item-user" @click.prevent="userIconClick">
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.78076 8.00756C10.0996 7.34513 11.0059 5.96997 11.0059 4.38095C11.0059 2.14551 9.21227 0.333328 6.99967 0.333328C4.78708 0.333328 2.99341 2.14551 2.99341 4.38095C2.99341 5.96997 3.89973 7.34513 5.21858 8.00756C2.40144 8.79471 0.333008 11.4033 0.333008 14.5H1.64756C1.64756 11.5136 4.04378 9.09263 6.99967 9.09263C9.95556 9.09263 12.3518 11.5136 12.3518 14.5H13.6663C13.6663 11.4033 11.5979 8.79471 8.78076 8.00756ZM9.69138 4.38095C9.69138 5.88288 8.48626 7.10044 6.99967 7.10044C5.51309 7.10044 4.30797 5.88288 4.30797 4.38095C4.30797 2.87901 5.51309 1.66145 6.99967 1.66145C8.48626 1.66145 9.69138 2.87901 9.69138 4.38095Z" />
              </svg>
            </a>
          </template>
          <DropdownMenuItem @click="signOut">
            Logout
          </DropdownMenuItem>
          <DropdownMenuItem @click="$router.push('/saved')">
            Lists
          </DropdownMenuItem>
        </DropdownMenu>
      </ClientOnly>
    </div>

    <div class="px-6 py-4 lg:hidden">
      <Logo :icon-color="scroll ? scrollLogoIconColor : logoIconColor" :text-color="scroll ? scrollLogoTextColor : logoTextColor" />
    </div>

    <div class="px-6 py-5 lg:hidden">
      <a href="#" class="inline-block" @click.prevent="showMenu = true">
        <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0095 2L0.00952148 2V0L16.0095 1.54972e-06V2ZM16.0095 11H0.00952148V9H16.0095V11Z" :fill="scroll ? scrollLogoIconColor : logoIconColor" />
        </svg>
      </a>

      <div
        class="fixed inset-0 z-50 ml-12 flex flex-col justify-between bg-white shadow-2xl lg:hidden"
        :class="{ block: showMenu, hidden: !showMenu }"
      >
        <a href="#" class="absolute right-4 top-6 block p-4" @click.prevent="showMenu = false">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.70718 9.12124L1.41421 15.4142L0 14L6.29297 7.70703L0.000150681 1.41421L1.41436 0L7.70718 6.29282L14 0L15.4142 1.41421L9.1214 7.70703L15.4144 14L14.0002 15.4142L7.70718 9.12124Z" fill="#4D4D4D" />
          </svg>
        </a>

        <div class="mt-32">
          <NuxtLink class="block py-4 pl-10 align-middle text-xl font-medium uppercase text-slate" to="/" @click="showMenu = false">
            Home
          </NuxtLink>
          <NuxtLink class="block py-4 pl-10 align-middle text-xl font-medium uppercase text-slate" to="/experts-choice-awards" @click="showMenu = false">
            Awards
          </NuxtLink>
          <NuxtLink class="block py-4 pl-10 align-middle text-xl font-medium uppercase text-slate" to="/about" @click="showMenu = false">
            About
          </NuxtLink>
          <NuxtLink class="block py-4 pl-10 align-middle text-xl font-medium uppercase text-slate" to="/explore" @click="showMenu = false">
            Explore
          </NuxtLink>
          <NuxtLink class="block py-4 pl-10 align-middle text-xl font-medium uppercase text-slate" to="/api" @click="showMenu = false">
            API
          </NuxtLink>
        </div>
        <div class="mb-32">
          <ClientOnly>
            <a class="flex gap-3 py-3 pl-10 align-middle text-xl font-medium text-slate" href="#" @click.prevent="logInOut">
              <span class="rounded-full bg-gray-6 p-2 align-middle">
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.78076 8.00756C10.0996 7.34513 11.0059 5.96997 11.0059 4.38095C11.0059 2.14551 9.21227 0.333328 6.99967 0.333328C4.78708 0.333328 2.99341 2.14551 2.99341 4.38095C2.99341 5.96997 3.89973 7.34513 5.21858 8.00756C2.40144 8.79471 0.333008 11.4033 0.333008 14.5H1.64756C1.64756 11.5136 4.04378 9.09263 6.99967 9.09263C9.95556 9.09263 12.3518 11.5136 12.3518 14.5H13.6663C13.6663 11.4033 11.5979 8.79471 8.78076 8.00756ZM9.69138 4.38095C9.69138 5.88288 8.48626 7.10044 6.99967 7.10044C5.51309 7.10044 4.30797 5.88288 4.30797 4.38095C4.30797 2.87901 5.51309 1.66145 6.99967 1.66145C8.48626 1.66145 9.69138 2.87901 9.69138 4.38095Z" fill="#262626" />
                </svg>
              </span>
              <span v-if="user">Log Out</span>
              <span v-else>Log In</span>
            </a>
            <DropdownSelect :value="currency.code" :options="selectCurrencies" mobile-title="Currencies" align="right" @input="code => updateCurrency(code)">
              <template #button="{ click }">
                <button class="ml-1 flex items-center gap-1 py-3 pl-20 align-middle text-xl font-medium text-slate focus:outline-none" @click="click">
                  <span>{{ symbol }}</span>
                  <svg
                    class="mt-0.5"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.649682L0.649682 0L4 3.35032L7.35032 0L8 0.649682L4 4.64968L0 0.649682Z" fill="#262626" />
                  </svg>
                </button>
              </template>
            </DropdownSelect>
            <NuxtLink v-if="user" class="ml-1 block py-3 pl-20 align-middle text-xl font-medium text-slate" to="/saved">
              Lists
            </NuxtLink>
          </ClientOnly>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import Logo from './Logo'
import { useCurrenciesStore } from '~/stores/currencies'
import { useAuthStore } from '~/stores/auth'
import venueTypes from '~/config/venue-types.json'
import DropdownMenu from '~/components/shared/DropdownMenu'
import DropdownMenuItem from '~/components/shared/DropdownMenuItem'
import SearchDropdown from '~/components/search-dropdown/SearchDropdown'
import decode from '~/utils/decode-ent'
import DropdownSelect from '~/components/shared/DropdownSelect'
import SpinnerSm from '~/components/shared/SpinnerSm'

export default {
  components: { SpinnerSm, DropdownSelect, SearchDropdown, DropdownMenuItem, DropdownMenu, Logo },

  props: {
    showSearch: { type: Boolean, default: true },
    menuItemClass: { type: String, default: 'text-slate' },
    logoIconColor: { type: String, default: '#262BC5' },
    logoTextColor: { type: String, default: '#231F20' },
    scrollClass: { type: String, default: 'shadow-md' },
    scrollMenuItemClass: { type: String, default: 'text-slate' },
    scrollLogoIconColor: { type: String, default: '#262BC5' },
    scrollLogoTextColor: { type: String, default: '#231F20' },
  },

  data: () => ({
    showMenu: false,
    venueTypes,
    scroll: false,
    showUserMenu: false,
    showCurrencyMenu: false,
  }),

  computed: {
    ...mapState(useCurrenciesStore, { currency: 'currency', symbol: 'symbol', currencies: s => s.currencies.data }),
    ...mapState(useAuthStore, ['user', 'loading']),

    selectCurrencies () {
      return this.currencies.map(c => ({
        id: c.code,
        label: decode(`${c.symbol} ${c.code}`),
      }))
    },
  },

  watch: {
    user (val) {
      if (! val) { this.showUserMenu = false }
    },
  },

  created () {
    // if (! process.client) { return }
    // window.addEventListener('scroll', this.handleScroll)
  },

  unmounted () {
    // window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    ...mapActions(useCurrenciesStore, ['updateCurrency']),
    ...mapActions(useAuthStore, ['signOut']),

    // handleScroll () {
    //   if (window.scrollY > 0 && ! this.scroll) {
    //     this.scroll = true
    //   } else if (window.scrollY === 0 && this.scroll) {
    //     this.scroll = false
    //   }
    // },

    userIconClick () {
      if (this.user) {
        this.showUserMenu = ! this.showUserMenu
      } else {
        this.$eventHub.emit('show-auth')
        setTimeout(() => {
          if (this.user) { this.showUserMenu = true }
        }, 500)
      }
    },

    logInOut () {
      if (this.user) {
        this.signOut()
      } else {
        this.$eventHub.emit('show-auth')
      }
      this.showMenu = false
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-item {
  @apply p-3 align-middle font-medium rounded-lg shrink-0;
  &:hover {
    @apply text-blue bg-gray-light;
    path { fill: #4348DB; }
  }
}
.menu-item-user {
  @apply block p-2 bg-gray-6 rounded-full;
  path {
    fill: #262626;
  }
  &:hover {
    @apply bg-gray-6;
    path {
      fill: #4348DB;
    }
  }
}
</style>
