<template>
  <NuxtLink
    :key="venue.id"
    :to="`/${venue.path}`"
    class="flex px-4 py-2 hover:bg-blue-highlight-2"
  >
    <div class="mr-6 size-12 shrink-0 items-center justify-center overflow-hidden rounded-lg bg-gray-6">
      <img v-if="venue.photo_thumbnail" :src="venue.photo_thumbnail" :alt="venue.name" class="size-full">
    </div>
    <div>
      <div class="block text-xl leading-tight text-gray-dark">
        {{ venue.name }}
      </div>
      <div class="block text-sm text-gray-dark">
        <span>{{ venue.destination_name }}</span>
      </div>
    </div>
  </NuxtLink>
</template>

<script>
export default {
  props: {
    venue: { type: Object, required: true },
  },
}
</script>
