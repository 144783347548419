<template>
  <div class="inline-block max-w-full text-left md:relative">
    <div ref="button">
      <slot name="button" />
    </div>
    <div
      class="fixed bottom-0 z-10 mt-4 w-full shadow-dropdown md:absolute md:bottom-auto md:w-56 md:p-0"
      :class="[dropdownClass, { hidden: !modelValue, 'right-0': right, 'left-0': !right }]"
    >
      <div v-if="modelValue" ref="content" class="dropdown-body rounded-md bg-white shadow-lg">
        <div class="relative border-b border-gray-6 px-5 py-4 md:hidden">
          <a href="#" class="absolute right-0 top-0 m-1 mt-2 block p-4" @click.prevent="$emit('update:modelValue', false)">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 9.46788L1.46794 16L0 14.532L6.53206 7.99992L0.000156405 1.46796L1.4681 0L8 6.53196L14.5319 0L15.9998 1.46796L9.46794 7.99992L16 14.532L14.5321 16L8 9.46788Z" fill="#4D4D4D" />
            </svg>
          </a>
          <div v-if="mobileTitle" class="text-xl font-medium text-gray-dark">
            {{ mobileTitle }}
          </div>
        </div>
        <div class="dropdown-content md:height-auto overflow-y-auto py-1 md:overflow-visible">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: { type: Boolean, required: true },
    mobileTitle: { type: String, default: '' },
    dropdownClass: { type: String, default: '' },
    width: { type: String, default: '14rem' },
    right: { type: Boolean, default: false },
  },

  emits: ['update:modelValue'],

  beforeUnmount () {
    document.removeEventListener('click', this.documentClick)
  },

  mounted () {
    document.addEventListener('click', this.documentClick)
  },

  methods: {
    documentClick (e) {
      if (! this.$refs.content || ! this.modelValue) { return }
      if (! this.$refs.content.contains(e.target) && ! this.$refs.button.contains(e.target)) {
        this.$emit('update:modelValue', false)
      }
    },
  },
}
</script>
