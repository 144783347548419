import { defineStore } from 'pinia'
import countries from '~/config/countries.json'

export const useGeneralStore = defineStore('general', {
  state: () => ({
    ipData: {},
  }),

  actions: {
    async setIpData (headers) {
      if (process.client) { return }

      const country = countries.find(c => c.code === headers['cf-ipcountry'])

      this.ipData = {
        ip: headers['true-client-ip'] || headers['cf-connecting-ip'],
        city: headers['cf-ipcity'],
        country: headers['cf-ipcountry'],
        continent: headers['cf-ipcontinent'],
        longitude: headers['cf-iplongitude'],
        latitude: headers['cf-iplatitude'],
        currency: country && country.curr,
      }
    },
  },
})
