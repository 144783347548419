<template>
  <div class="m-2 flex rounded bg-gray-6 px-4 py-3 lg:m-0">
    <div class="mr-2" style="margin-top: 2px;" @click="$emit('click')">
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2832 13.903C11.0043 14.8428 9.42419 15.3981 7.71417 15.3981C3.45375 15.3981 0 11.9512 0 7.69907C0 3.44699 3.45375 0 7.71417 0C11.9746 0 15.4283 3.44699 15.4283 7.69907C15.4283 9.38966 14.8824 10.953 13.9568 12.223L19 17.3385L17.3081 19L12.2832 13.903ZM13.0548 7.69907C13.0548 10.6428 10.6637 13.0292 7.71417 13.0292C4.76465 13.0292 2.37359 10.6428 2.37359 7.69907C2.37359 4.75533 4.76465 2.36895 7.71417 2.36895C10.6637 2.36895 13.0548 4.75533 13.0548 7.69907Z" fill="#4348DB" />
      </svg>
    </div>
    <div class="w-full">
      <input
        v-if="!emojisQuery"
        :value="query"
        type="text"
        class="w-full bg-transparent focus:outline-none"
        :placeholder="placeholder"
        @input="$emit('update:query', $event.target.value)"
        @click="$emit('click')"
      >
      <input
        v-else
        :value="emojisQuery"
        type="text"
        class="w-full bg-transparent focus:outline-none"
        @input="$emit('update:emojisQuery', $event.target.value)"
        @click="$emit('click')"
      >
    </div>
    <span role="button" class="ml-2 mt-1 block" @click.prevent="$emit('closeDropdown')">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.70718 9.12124L1.41421 15.4142L0 14L6.29297 7.70703L0.000150681 1.41421L1.41436 0L7.70718 6.29282L14 0L15.4142 1.41421L9.1214 7.70703L15.4144 14L14.0002 15.4142L7.70718 9.12124Z" fill="#333333" />
      </svg>
    </span>
  </div>
</template>

<script>
export default {

  props: {
    opened: Boolean,
    query: { type: String, required: true },
    emojisQuery: { type: String, required: true },
    placeholder: { type: String, default: '' },
  },

  emits: ['update:emojisQuery', 'click', 'update:query', 'closeDropdown'],
}
</script>
