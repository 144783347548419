export default function (item, openTag = '<b>', closeTag = '</b>') {
  const highlights = {}

  item.matches.forEach((match) => {
    const text = item.item[match.key]
    const result = []
    const matches = [].concat(match.indices)
    let pair = matches.shift()

    for (let i = 0; i < text.length; i++) {
      const char = text.charAt(i)
      if (pair && i === pair[0]) {
        result.push(openTag)
      }
      result.push(char)
      if (pair && i === pair[1]) {
        result.push(closeTag)
        pair = matches.shift()
      }
    }
    highlights[match.key] = result.join('')
  })

  return highlights
}
