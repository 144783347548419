<template>
  <svg viewBox="0 0 144 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.3272 11.2603H8.00557C7.92928 13.0553 7.00103 14.7581 5.68115 15.4966V15.6376H17.6466V15.4966C16.3267 14.7581 15.3984 13.0553 15.3221 11.2603" :fill="iconColor" />
    <path d="M11.6704 2.70544C19.076 2.70544 23.3459 7.50339 23.3459 15.4145C23.3459 15.4837 23.3459 15.5504 23.3459 15.6171C22.1308 15.455 20.9853 14.9521 20.0398 14.1656C19.312 13.5731 18.7857 12.7663 18.5343 11.8577C17.4814 7.95729 15.2282 6.21608 11.6831 6.17249H11.6653C8.12529 6.21352 5.867 7.96754 4.81414 11.8577C4.56302 12.767 4.03561 13.5742 3.30606 14.1656C2.36103 14.9528 1.2153 15.4558 0 15.6171C0 15.5504 0 15.4837 0 15.4145C0 7.50339 4.27754 2.70544 11.6806 2.70544" :fill="iconColor" />
    <path d="M41.2928 2.649H35.9929V18.3096H32.6055V2.649H27.2827V0H41.2928V2.649Z" :fill="textColor" />
    <path d="M49.5375 6.03912L48.6042 8.95738C48.0771 8.66854 47.4874 8.51528 46.8875 8.51118C44.998 8.51118 43.8688 10.0703 43.8688 12.6937V18.3097H40.6289V5.89039H43.8638V11.0191H43.9121C43.9121 7.62903 45.3108 5.64933 47.6428 5.64933C48.2953 5.6352 48.9427 5.76838 49.5375 6.03912Z" :fill="textColor" />
    <path d="M50.6895 0.420532H53.9268V3.51317H50.6895V0.420532ZM50.6895 5.89034H53.9268V18.3096H50.6895V5.89034Z" :fill="textColor" />
    <path d="M69.2623 12.0987C69.2623 16.1581 67.4236 18.5584 64.3311 18.5584C61.582 18.5584 59.9391 16.8736 59.9391 14.0297H59.8857V24H56.6509V5.89035H59.8857V10.1446H59.9341C59.9341 7.32383 61.5769 5.6416 64.326 5.6416C67.4236 5.6416 69.2623 8.04186 69.2623 12.0987ZM65.9562 12.0987C65.9562 9.67536 64.8016 8.23932 62.8383 8.23932C60.875 8.23932 59.723 9.79845 59.723 12.0987C59.723 14.3989 60.875 15.9581 62.8383 15.9581C64.8016 15.9581 65.9511 14.5246 65.9511 12.0987H65.9562Z" :fill="textColor" />
    <path d="M82.7738 12.4705H73.8017C73.9238 14.7477 75.0021 16.0837 76.7441 16.0837C78.27 16.0837 79.3203 15.2426 79.6407 13.8809H82.7738C82.3313 16.8249 80.0756 18.5584 76.7365 18.5584C72.9218 18.5584 70.6533 16.1581 70.6533 12.0987C70.6533 8.03929 72.9091 5.6416 76.7365 5.6416C80.5156 5.6416 82.7738 8.01621 82.7738 12.0987V12.4705ZM73.817 11.3576H79.6077C79.4118 9.28045 78.3564 8.09058 76.7365 8.09058C75.1165 8.09058 74.0458 9.28045 73.8246 11.3576H73.817Z" :fill="textColor" />
    <path d="M109.057 12.0987C109.057 16.1581 107.215 18.5584 104.126 18.5584C101.376 18.5584 99.7336 16.8736 99.7336 14.0297H99.6827V24H96.4453V5.89035H99.6827V10.1446H99.7336C99.7336 7.32383 101.376 5.6416 104.126 5.6416C107.215 5.6416 109.057 8.04186 109.057 12.0987ZM105.751 12.0987C105.751 9.67536 104.599 8.23932 102.635 8.23932C100.672 8.23932 99.52 9.79845 99.52 12.0987C99.52 14.3989 100.672 15.9581 102.635 15.9581C104.599 15.9581 105.743 14.5246 105.743 12.0987H105.751Z" :fill="textColor" />
    <path d="M122.576 12.4705H113.594C113.719 14.7477 114.797 16.0837 116.539 16.0837C118.065 16.0837 119.115 15.2426 119.435 13.8809H122.576C122.134 16.8249 119.875 18.5584 116.539 18.5584C112.724 18.5584 110.453 16.1581 110.453 12.0987C110.453 8.03929 112.711 5.6416 116.539 5.6416C120.318 5.6416 122.576 8.01621 122.576 12.0987V12.4705ZM113.619 11.3576H119.41C119.214 9.28045 118.159 8.09058 116.539 8.09058C114.919 8.09058 113.841 9.28045 113.619 11.3576Z" :fill="textColor" />
    <path d="M133.494 6.03914L132.56 8.9574C132.033 8.66856 131.443 8.5153 130.844 8.5112C128.954 8.5112 127.825 10.0703 127.825 12.6937V18.3097H124.585V5.8904H127.825V11.0192H127.873C127.873 7.62905 129.272 5.64935 131.604 5.64935C132.255 5.63599 132.9 5.76915 133.494 6.03914Z" :fill="textColor" />
    <path d="M139.551 8.1419V14.1297C139.551 15.2914 140.289 15.9581 141.515 15.9581C142.086 15.9634 142.65 15.8278 143.157 15.5632V17.9891C142.315 18.3627 141.405 18.5565 140.485 18.5584C137.858 18.5584 136.314 17.0736 136.314 14.5503V8.13933H134.546V5.88781H136.51V1.73352H139.739V2.77209C139.739 4.35687 138.562 5.46981 136.599 5.84934V5.90063H143.371V8.15215L139.551 8.1419Z" :fill="textColor" />
    <path d="M93.2285 13.8656C93.0468 13.5109 92.798 13.1956 92.4961 12.9373C91.6572 12.2247 90.6109 11.807 89.5155 11.7474H88.8365C87.6893 11.8064 86.5964 12.2588 85.739 13.0296C85.488 13.2617 85.2812 13.5381 85.1287 13.8451L82.8398 18.2712H86.5197L89.1646 12.5603L91.8094 18.2712H95.4919L93.2285 13.8656Z" :fill="textColor" />
    <path d="M85.8714 10.5704C86.7087 11.2738 87.7485 11.6866 88.8366 11.7474H89.5157C90.6032 11.6871 91.6423 11.2743 92.4784 10.5704C92.7969 10.3014 93.0567 9.96868 93.2413 9.59334L95.0876 5.89294H91.5273L89.1749 11.0704L86.825 5.89294H83.2646L85.111 9.59334C85.2946 9.96857 85.5536 10.3013 85.8714 10.5704Z" :fill="textColor" />
  </svg>
</template>

<script>
export default {
  props: {
    iconColor: { type: String, default: '#262BC5' },
    textColor: { type: String, default: '#231F20' },
  },
}
</script>
