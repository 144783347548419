import { defineStore } from 'pinia'
import { useGeneralStore } from '~/stores/general'
import decode from '~/utils/decode-ent'
import readJsonConfigFile from '~/utils/read-json-config-file'

const defaultCurrency = {
  code: 'USD',
  id: 1,
  name: 'US Dollar',
  symbol: '$',
  value_usd: 1,
}

export const useCurrenciesStore = defineStore('currencies', {
  state: () => ({
    currCode: 'USD',
    setDefault: true,
    currencies: {
      data: [],
    },
  }),

  actions: {
    async loadCurrencies () {
      if (process.client) { return }
      try {
        this.currencies.data = await readJsonConfigFile('currencies-runtime.json')
        this.setDefaultCurrency()
      } catch (e) {
        this.currencies.data = (await import('~/config/currencies-fallback.json')).default
      }
    },

    updateCurrency (code) {
      this.currCode = code
      this.setDefault = false
    },

    setDefaultCurrency () {
      if (this.setDefault && useGeneralStore().ipData.currency && this.currencies.data.length) {
        const code = useGeneralStore().ipData.currency
        if (code && this.currencies.data.find(c => c.code === code)) {
          this.currCode = code
          this.setDefault = false
        }
      }
    },
  },

  getters: {
    currency () {
      return this.currencies.data.find(c => c.code === this.currCode) || defaultCurrency
    },

    symbol () {
      return decode(this.currency.symbol)
    },

    rate () {
      return this.currency.value_usd
    },

    code () {
      return this.currency.code
    },
  },
})
