<template>
  <div class="search-dropdown">
    <div v-if="showDropdown" class="fixed inset-0 z-40" style="background-color: rgba(255,255,255,0.6);" @click="$emit('closeDropdown')" />

    <div
      :style="showDropdown ? 'box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);' : ''"
      class="rounded"
      :class="[
        { 'z-50': showDropdown },
        showDropdown && !home ? 'absolute' : 'relative',
      ]"
    >
      <slot name="input" />

      <div
        v-if="showDropdown"
        :style="showDropdown ? 'box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);' : ''"
        class="absolute w-full overflow-hidden rounded-b border border-t-0 border-slate bg-white pb-4"
      >
        <div class="w-full overflow-y-auto pb-4 lg:max-h-100">
          <slot name="results" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showDropdown: Boolean,
    home: Boolean,
  },

  emits: ['closeDropdown'],
}
</script>
