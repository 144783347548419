<template>
  <NuxtLink
    :key="destination.id"
    :to="`/${destination.path}`"
    class="flex px-4 py-2 hover:bg-blue-highlight-2"
  >
    <div class="mr-6 flex size-12 items-center justify-center rounded-lg border border-gray-6">
      <img src="~/assets/images/markers/marker-gray.svg" alt="Destination">
    </div>
    <div>
      <div class="block text-xl leading-tight text-gray-dark">
        {{ destination.name }}
      </div>
      <div class="block text-sm text-gray-dark">
        <span v-if="destination.locality_highlights"><span class="text-xs">includes</span> {{ destination.locality_highlights }}</span>
        <span v-else>{{ destination.country_name }}</span>
      </div>
    </div>
  </NuxtLink>
</template>

<script>
export default {
  props: {
    destination: { type: Object, required: true },
  },
}
</script>
