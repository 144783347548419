<template>
  <div>
    <SearchDropdownDesktop
      v-if="!isMobile"
      :show-dropdown="showDropdown"
      :query="query"
      :selected-emojis="selectedEmojis"
      home
      @close-dropdown="showDropdown = false"
    >
      <template #input>
        <SearchDropdownInputHome
          v-if="home"
          v-model:emojis-query="emojisQuery"
          v-model:query="query"
          :opened="showDropdown"
          :placeholder="placeholder"
          @click="showDropdown = true"
        />
        <SearchDropdownInput
          v-else
          v-model:emojis-query="emojisQuery"
          v-model:query="query"
          :opened="showDropdown"
          :placeholder="placeholder"
          width="w-full xl:w-full max-w-144"
          @click="showDropdown = true"
        />
      </template>
      <template #results>
        <SearchDropdownResults
          v-model:emojis-query="emojisQuery"
          :query="query"
          :selected-emojis="selectedEmojis"
          @close-dropdown="showDropdown = false"
        />
      </template>
    </SearchDropdownDesktop>

    <SearchDropdownMobile
      v-if="isMobile"
      :show-dropdown="showDropdown"
      :query="query"
      :selected-emojis="selectedEmojis"
      @close-dropdown="showDropdown = false"
    >
      <template #input>
        <SearchDropdownInputHome
          v-if="!showDropdown && home"
          v-model:emojis-query="emojisQuery"
          v-model:query="query"
          :opened="showDropdown"
          :placeholder="placeholder"
          @click="showDropdown = true"
        />
        <div v-if="!showDropdown && !home" class="m-2 rounded px-4 py-3" @click="showDropdown = true">
          <div class="" style="margin-top: 2px;">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.2832 13.903C11.0043 14.8428 9.42419 15.3981 7.71417 15.3981C3.45375 15.3981 0 11.9512 0 7.69907C0 3.44699 3.45375 0 7.71417 0C11.9746 0 15.4283 3.44699 15.4283 7.69907C15.4283 9.38966 14.8824 10.953 13.9568 12.223L19 17.3385L17.3081 19L12.2832 13.903ZM13.0548 7.69907C13.0548 10.6428 10.6637 13.0292 7.71417 13.0292C4.76465 13.0292 2.37359 10.6428 2.37359 7.69907C2.37359 4.75533 4.76465 2.36895 7.71417 2.36895C10.6637 2.36895 13.0548 4.75533 13.0548 7.69907Z" fill="#4348DB" /></svg>
          </div>
        </div>
        <SearchDropdownInputMobile
          v-if="showDropdown"
          v-model:emojis-query="emojisQuery"
          v-model:query="query"
          :opened="showDropdown"
          :placeholder="placeholder"
          @click="showDropdown = true"
          @close-dropdown="showDropdown = false"
        />
      </template>
      <template #results>
        <SearchDropdownResults
          v-model:emojis-query="emojisQuery"
          :query="query"
          :selected-emojis="selectedEmojis"
          @close-dropdown="showDropdown = false"
        />
      </template>
    </SearchDropdownMobile>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapState } from 'pinia'
import { useSearchStore } from '~/stores/search'
import travelTypes from '~/config/travel-types.json'
import SearchDropdownInput from '~/components/search-dropdown/SearchDropdownInput'
import SearchDropdownDesktop from '~/components/search-dropdown/SearchDropdownDesktop'
import SearchDropdownResults from '~/components/search-dropdown/SearchDropdownResults'
import SearchDropdownMobile from '~/components/search-dropdown/SearchDropdownMobile'
import SearchDropdownInputMobile from '~/components/search-dropdown/SearchDropdownInputMobile'
import SearchDropdownInputHome from '~/components/search-dropdown/SearchDropdownInputHome'

export default {
  components: { SearchDropdownInputHome, SearchDropdownInputMobile, SearchDropdownMobile, SearchDropdownResults, SearchDropdownDesktop, SearchDropdownInput },

  props: {
    home: Boolean,
    placeholder: { type: String, default: 'Where are you going?' },
  },

  emits: ['input'],

  data: () => ({
    query: '',
    emojisQuery: '',
    selectedEmojis: [],
    showDropdown: false,
  }),

  computed: {
    ...mapState(useSearchStore, {
      nearbyDestinations: s => s.nearbyDestinations.data,
      venues: s => s.search.data.venues,
      loading: s => s.search.loading,
    }),

    isMobile () { return ['sm', 'md'].includes(this.$screen.name) },
  },

  watch: {
    query (val) {
      if (val) {
        this.search(val)
      } else {
        this.clearSearch()
      }
    },

    emojisQuery (val) {
      const types = [...val].map(e => travelTypes.find(t => t.emoji === e)).filter(i => i)
      this.emojisQuery = types.map(e => e.emoji).join(' ')

      // Don't re-assing array if it was not changed
      if (JSON.stringify(types) === JSON.stringify(this.selectedEmojis)) { return }
      this.selectedEmojis = types

      if (this.selectedEmojis.length) {
        this.searchEmoji(this.selectedEmojis)
      } else {
        this.clearSearch()
      }
    },

    async showDropdown (val) {
      if (val && this.isMobile) {
        setTimeout(() => {
          document.querySelector('html').scrollTop = 0
        }, 100)
      }

      if (! this.nearbyDestinations.length) {
        await this.loadNearbyDestinations()
      }
    },
  },

  methods: {
    ...mapActions(useSearchStore, ['loadSearch', 'loadDestinations', 'loadNearbyDestinations', 'clearSearch']),

    search: debounce(function (query) {
      this.loadSearch(query)
    }, 300),

    searchEmoji (types) {
      this.loadDestinations({ travel_type_id: types.map(t => t.id).join(',') })
    },

    clearClose () {
      this.query = ''
      this.clearSearch()
      this.$emit('input', false)
    },
  },
}
</script>
