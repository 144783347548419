import { defineStore } from 'pinia'
import { useGeneralStore } from './general'
import { useApi } from '~/composables/useApi'

export const useSearchStore = defineStore('search', {
  state: () => ({
    search: {
      loading: false,
      data: {
        destinations: [],
        venues: [],
      },
    },
    destinations: {
      loading: false,
      data: [],
    },
    nearbyDestinations: {
      loading: false,
      data: [],
    },
  }),

  actions: {
    async loadSearch (query) {
      this.search.loading = true
      try {
        const resp = await useApi('autocomplete.json', { query: { q: query } })
        this.search.data.destinations = resp.response.destinations.results
        this.search.data.venues = resp.response.venues.results
      } finally {
        this.search.loading = false
      }
    },

    async loadDestinations (params) {
      this.destinations.loading = true
      try {
        const resp = await useApi('destinations.json', { query: { limit: 3, ...params } })
        this.destinations.data = resp.response.destinations
      } finally {
        this.destinations.loading = false
      }
    },

    async loadNearbyDestinations () {
      this.nearbyDestinations.loading = true
      try {
        const resp = await useApi('autocomplete.json', {
          query: {
            latitude: useGeneralStore().ipData.latitude,
            longitude: useGeneralStore().ipData.longitude,
            limit: 5,
          },
        })
        this.nearbyDestinations.data = resp.response.destinations
      } finally {
        this.nearbyDestinations.loading = false
      }
    },

    clearSearch () {
      this.search.data.destinations = []
      this.search.data.venues = []
      this.destinations.data = []
    },
  },
})
