<template>
  <div class="inline-block max-w-full text-left md:relative">
    <div ref="button">
      <slot name="button" :click="() => show = !show">
        <button @click="show = !show">
          Select
        </button>
      </slot>
    </div>

    <div
      v-if="show"
      class="dropdown fixed bottom-0 z-40 mt-4 w-full overflow-hidden shadow-dropdown md:absolute md:bottom-auto md:z-20 md:w-56 md:rounded md:p-0"
      :class="classes"
    >
      <div ref="content" class="dropdown-body rounded-md bg-white shadow-lg">
        <div class="relative border-b border-gray-6 px-5 py-4 md:hidden">
          <a href="#" class="absolute right-0 top-0 m-1 mt-2 block p-4" @click.prevent="show = false">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 9.46788L1.46794 16L0 14.532L6.53206 7.99992L0.000156405 1.46796L1.4681 0L8 6.53196L14.5319 0L15.9998 1.46796L9.46794 7.99992L16 14.532L14.5321 16L8 9.46788Z" fill="#4D4D4D" />
            </svg>
          </a>
          <div v-if="mobileTitle" class="text-xl font-medium text-gray-dark">
            {{ mobileTitle }}
          </div>
        </div>
        <div v-if="showSearch" class="mx-1 mb-4 mt-1 flex rounded border border-gray-mid px-4 py-1">
          <div class="mr-2 mt-1">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5879 11.7377C9.50049 12.5312 8.1571 13 6.70322 13C3.08096 13 0.144531 10.0899 0.144531 6.5C0.144531 2.91015 3.08096 0 6.70322 0C10.3255 0 13.2619 2.91015 13.2619 6.5C13.2619 7.92729 12.7977 9.24714 12.0108 10.3194L16.2986 14.6382L14.8601 16.0409L10.5879 11.7377ZM11.2439 6.5C11.2439 8.98528 9.21094 11 6.70322 11C4.1955 11 2.16259 8.98528 2.16259 6.5C2.16259 4.01472 4.1955 2 6.70322 2C9.21094 2 11.2439 4.01472 11.2439 6.5Z" fill="#808080" />
            </svg>
          </div>
          <input v-model="query" type="text" class="w-full focus:outline-none" placeholder="Search">
        </div>
        <div class="dropdown-content md:height-auto max-h-full overflow-auto py-1">
          <a
            v-for="(option, idx) in filtered"
            :key="idx"
            class="relative block px-12 py-2 text-base text-gray-dark hover:bg-blue-highlight md:px-6 md:text-sm"
            href="#"
            @click.prevent="() => select(option.item)"
          >
            <svg
              v-if="selected(option.item)"
              class="absolute left-8 top-4 md:left-2"
              width="8"
              height="6"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0.614085L3.18064 5.47477L0 2.61836L0.562199 1.9512L3.12675 4.29989L7.37903 0L8 0.614085Z" fill="#4D4D4D" />
            </svg>
            <span v-html="highlightName(option)" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'
import fuseHighlight from '~/utils/fuse-highlight'

export default {
  props: {
    modelValue: { type: [String, Number], default: null },
    mobileTitle: { type: String, default: '' },
    options: { type: Array, default: () => [] },
    dropdownClass: { type: String, default: '' },
    width: { type: String, default: '14rem' },
    showSearch: { type: Boolean, default: false },
    align: { type: String, default: 'left' },
    adaptive: { type: Boolean, default: false },
  },

  emits: ['update:modelValue'],

  data: () => ({
    show: false,
    query: '',
    fuse: null,
  }),

  computed: {
    filtered () {
      if (! this.query) {
        return this.options.map(i => ({ item: i }))
      }

      return this.fuse.search(this.query)
    },

    classes () {
      return [
        this.dropdownClass, {
          'hidden': ! this.show,
          'right-0 lg:right-auto lg:left-0': this.adaptive && this.align === 'left',
          'left-0': this.align === 'left' && ! this.adaptive,
          'right-0': this.align !== 'left',
        },
      ]
    },
  },

  watch: {
    options () {
      this.makeFuse()
    },
  },

  beforeMount () {
    this.makeFuse()
  },

  beforeUnmount () {
    document.removeEventListener('click', this.documentClick)
  },

  mounted () {
    document.addEventListener('click', this.documentClick)
  },

  methods: {
    select (option) {
      this.$emit('update:modelValue', option.id)
      this.show = false
    },

    selected (option) {
      return this.modelValue === option.id
    },

    highlightName (item) {
      if (! item.matches) { return item.item.label }

      const highlights = fuseHighlight(item, '<span class="bg-blue bg-opacity-10">', '</span>')

      return highlights.label
    },

    makeFuse () {
      this.fuse = new Fuse(this.options, {
        includeMatches: true,
        threshold: 0.3,
        keys: ['label'],
      })
    },

    documentClick (e) {
      if (! this.$refs.content || ! this.show) { return }
      if (! this.$refs.content.contains(e.target) && ! this.$refs.button.contains(e.target)) {
        this.show = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  //.dropdown { max-height: 500px; }
  .dropdown-content { max-height: 500px; }
}
@media (max-width: 767px) {
  .dropdown-content { max-height: calc(100vh - 7.1rem); }
}
</style>
