<template>
  <NuxtLink to="/" class="mr-1 mt-1 inline-block align-middle">
    <LogoIcon width="144" height="24" />
  </NuxtLink>
</template>

<script>
import LogoIcon from '~/components/icons/LogoIcon'

export default {
  components: { LogoIcon },

  props: {
    iconColor: { type: String, default: '#262BC5' },
    textColor: { type: String, default: '#231F20' },
  },
}
</script>
