import { showError, useRuntimeConfig } from 'nuxt/app'
import type { FetchOptions } from 'ofetch'
import { $fetch } from 'ofetch'
import merge from 'lodash/merge'
import qs from 'qs'

export async function useApi (url: string, options: FetchOptions = {}) {
  const config = useRuntimeConfig()

  if (! options.query) { options.query = {} }
  options.query.api_key = config.public.apiKey

  if (options.query) {
    url += `?${qs.stringify(options.query, {
     arrayFormat: 'brackets',
     filter: (prefix: any, value: any) => value !== null ? value : undefined,
   })}`
    delete options.query
  }

  try {
    return await $fetch(url, merge({
      baseURL: config.public.apiUrl,
      headers: {
        //
      },
    }, options))
  } catch (e: any) {
    // Show 404 error page on 404 API response
    if (e?.response?.status === 404) {
      showError({ statusCode: 404, message: e.message })
    }

    throw e
  }
}
