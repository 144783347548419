<template>
  <div class="mt-40 bg-gray-6">
    <div class="px-6 py-16 lg:px-16 xl:px-32">
      <div class="lg:flex">
        <div class="lg:w-100 lg:pr-12">
          <a href="/">
            <LogoIcon width="121" class="mb-5" />
          </a>
          <div class="font-bw-gradual text-2xl font-bold leading-tight text-black">
            Travel reviews <br class="hidden lg:block">you can trust.
          </div>
        </div>
        <div class="mt-10 w-full lg:mt-0">
          <div class="flex flex-wrap justify-end" style="max-width: 900px;">
            <div class="w-1/2 lg:w-1/3">
              <NuxtLink to="/experts-choice-awards" class="link mb-2 block font-medium">
                Experts' Choice
              </NuxtLink>
              <NuxtLink to="/explore" class="link mb-2 block font-medium">
                Explore
              </NuxtLink>
            </div>
            <div class="w-1/2 lg:w-1/3">
              <NuxtLink to="/about" class="link mb-2 block font-medium">
                About
              </NuxtLink>
              <NuxtLink to="/api" class="link mb-2 block font-medium">
                API
              </NuxtLink>
              <NuxtLink to="/business-support" class="link mb-2 block font-medium">
                Business support
              </NuxtLink>
              <NuxtLink to="/contact" class="link mb-2 block font-medium">
                Contact
              </NuxtLink>
            </div>
            <div class="mt-10 w-1/2 lg:mt-0 lg:w-1/3">
              <a class="social-link mr-2" href="https://twitter.com/tripexpert" target="_blank" rel="noopener noreferrer">
                <img src="~assets/images/icons/twitter.svg" width="16" height="14" alt="" data-not-lazy>
              </a>
              <a class="social-link mr-2" href="https://www.instagram.com/tripexpert" target="_blank" rel="noopener noreferrer">
                <img src="~assets/images/icons/instagram.svg" width="16" height="16" alt="" data-not-lazy>
              </a>
            </div>
          </div>
        </div>
      </div>

      <hr class="mb-3 mt-16 border-gray-3">
      <div class="text-right">
        &copy; Tripexpert Inc.
      </div>
    </div>
  </div>
</template>

<script>
import LogoIcon from '~/components/icons/LogoIcon'

export default {
  components: { LogoIcon },
}
</script>

<style lang="scss" scoped>
.social-link {
  @apply w-8 h-8 bg-gray-dark text-white rounded-full inline-flex items-center justify-center;

  &:hover {
    @apply bg-blue;
  }
}
</style>
